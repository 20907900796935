import React from "react";
import "./Maintenance.css";
import maintenanceImage from "../assets/images/road-barrier.png";

export default function Maintenance() {
  return (
    <div className="maintenance">
      <label id="pluto-ask">Pluto Ask</label>
      <img src={maintenanceImage} alt="maintenance image"></img>
      <span>Sorry!</span>
      <span>We are currently under maintenance!</span>
    </div>
  );
}
