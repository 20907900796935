import "./App.css";
import ChatArea from "./ChatArea";
import Maintenance from "./Maintenance";

export default function App() {
  return (
    <div className="app">
      {/* <ChatArea /> */}
      <Maintenance />
    </div>
  );
}
